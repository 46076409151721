<template>
  <b-tooltip
    :animated="false"
    :append-to-body="true"
    position="is-left"
    :auto-close="['outside']"
    :triggers="['click']"
  >
    <b-tag type="is-small" class="user-comp">
      {{ locationData.street }}
    </b-tag>
    <template v-slot:content>
      <div class="toast-container">
        <div class="info-container">
          <label
            >Street:
            <span @click="copyToClipboard(locationData.street)"
              >{{ locationData.street }}
            </span>
          </label>
          <label
            >City:
            <span @click="copyToClipboard(locationData.city)">{{
              locationData.city
            }}</span></label
          >
          <label
            >Zip:
            <span @click="copyToClipboard(locationData.zip)">{{
              locationData.zip
            }}</span></label
          >
          <label
            >State:
            <span @click="copyToClipboard(locationData.state)">{{
              locationData.state
            }}</span></label
          >
          <label
            >County:
            <span @click="copyToClipboard(locationData.county)">{{
              locationData.county
            }}</span></label
          >
        </div>
        <div>
          <IconButton
            title="Copy All"
            icon="content-copy"
            @click="copyToClipboard(locationSummary)"
          />
        </div>
      </div>
    </template>
  </b-tooltip>
</template>
<script setup>
import { computed } from "vue";
import toastService from "../../services/toastService";
import IconButton from "./IconButton.vue";
const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
});
const locationData = computed(() => ({
  state: props.location?.state ? props.location?.state : "N/A",
  street: props.location?.street ? props.location?.street : "N/A",
  zip: props.location?.zip ? props.location?.zip : "N/A",
  city: props.location?.city ? props.location?.city : "N/A",
  county: props.location?.county ? props.location?.county : "N/A",
}));

const locationSummary = computed(() => {
  return `Street: ${locationData.value.street} \n City:${locationData.value.city} \nZip:${locationData.value.zip}  \n State:${locationData.value.state} \n County:${locationData.value.county}`;
});

async function copyToClipboard(text) {
  try {
    navigator.clipboard.writeText(text);
    toastService.success("Copied to clipboard");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
}
</script>
<style lang="css" scoped>
.user-comp {
  cursor: context-menu;
}
.toast-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-container {
  display: flex;
  flex-direction: column;
}
.info-container span {
  cursor: copy;
}
</style>

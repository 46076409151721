<template>
  <div class="driver-table-wrapper">
    <Table @rowOpened="rowOpenedHandler" :data="filteredDriversWithMetata" :columns="columns" :multipleDetails="false"
      detailKey="id" :loading="loading" :snapTo="selectedDriverId" :controls="true">
      <template #fullName="props">
        <User :value="props.value.user" :is-driver="true"/>
      </template>

      <template #followingDriver="props">
        <div v-if="props.value.followingDriver?.user">
          <User :value="props.value.followingDriver.user" />
        </div>
      </template>

      <template #controls>
        <filters :showTooltip="false">
          <div class="filters_columns" style="width: 100%;">
            <!-- row one filters -->
            <div style="display: flex; justify-content: space-between;">
              <span>
                <label for="driver_warehouse_select" class="mr-1">Warehouse:</label>
                <select id="driver_warehouse_select" @change="warehouseChanged">
                  <option value="" selected>All</option>
                  <option v-for="w in warehouses" :key="`driverselectwarehouse:${w.id}`" :value="w.id">
                    {{ w.name }}
                  </option>
                </select>
              </span>

              <span>
                <label class="mr-1 ml-1" for="driver-text-search">User: </label>
                <input id="driver-text-search" type="text" @input="searchTextChanged($event.target.value)" />
              </span>

              <span>
                <label for="roles_select" class="mr-1">Role:</label>
                <select id="roles_select" @change="roleChanged">
                  <option value="" selected>All</option>
                  <option v-for="w in roles" :key="`role:${w}`" :value="w">
                    {{ w }}
                  </option>
                </select>
              </span>
            </div>

            <div class="filters_rows">
              <span>Routed Drivers: {{ filteredRoutedDrivers.length }}</span>
              <span class="ml-5">Total Collected: {{ totalCollected }}</span>

              <span class="ml-5">
                <a @click="setShowAssociationWorkflow">Associate Drivers to Geotab</a>
              </span>
            </div>

            <div v-if="selectedWarehouse" class="filters_rows">
              <button @click="dumpAndClearAllRoutesFromSelectedWarehouse"
                :disabled="!driversAtSelectedWarehouseWithStopsWithMetadata.length">
                Dump &amp; Clear All Routes from {{ selectedWarehouse.name }} ({{
                  driversAtSelectedWarehouseWithStopsWithMetadata.length
                }})
              </button>

              <button @click="openVroom" class="ml-2">Vrooom</button>
            </div>
          </div>
        </filters>

        <b-button @click="refresh" size="is-small" icon-right="refresh" title="Refresh Table" class="mb-1" />
      </template>

      <template #totalStops="props">
        {{ props.value.stopCount }}
      </template>

      <template #detail="props">
        <div class="driver-edit-wrapper">
          <DriverEdit @rowOpened="rowOpenedHandler" :driver="props.value" @change="refresh" />
        </div>
      </template>
    </Table>

    <template v-if="visibleOnMap">
      <route-component v-for="d in filteredRoutedDriversExceptSelected" :key="`driverstable:route:${d.user.id}`"
        @click="driverClicked(d.user.id)" :route="d.activeRoute" :warehouse="d.warehouse"
        :colorScheme="d.colorScheme" />

      <driver-component v-for="d in filteredDriversWithGeotab" :key="`driverstable:geotab:${d.user.id}`"
        @click="driverClicked(d.user.id)" :driver="d" :geotabDriver="d.geotabDriver" />
    </template>

    <b-modal v-model="showAssociationWorkflow" @close="showAssociationWorkflowClosed">
      <associate-drivers-and-geotab :drivers="driversWithMetadata" :geotabDrivers="geotabDrivers" />
    </b-modal>

    <b-modal v-model="showVroom" @close="vroomClosed">
      <vroom-setup :warehouseId="filters?.warehouseId" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/components/common/Table";
import User from "@/components/common/User";
import Filters from "@/components/common/Filters.vue";

import Pp4MapObject from "@/components/maps/Pp4MapObject";
import RouteComponent from "@/components/maps/Route";
import DriverComponent from "@/components/maps/Driver";

import DriverEdit from "@/views/RouteWizard/components/DriverEdit";
import AssociateDriversAndGeotab from "@/views/RouteWizard/components/AssociateDriversAndGeotab";

import * as FormatUtil from "@/services/FormatUtil";
import * as GeoUtil from "@/services/GeoUtil";
import { getDriverName } from "@/services/UserUtil";
import VroomSetup from "@/components/vroom/VroomSetup";

export default {
  extends: Pp4MapObject,
  components: {
    Table,
    VroomSetup,
    DriverEdit,
    RouteComponent,
    DriverComponent,
    User,
    Filters,
    AssociateDriversAndGeotab,
  },
  inject: ["$mutate", "inActiveTab"],
  data() {
    return {
      getDriverName,
      showAssociationWorkflow: false,
      showVroom: false,
      selectedDriverId: null,
      filters: {
        role: null,
        warehouseId: null,
        searchText: "",
      },
      columns: [
        { field: "fullName", label: "Name", visible: true, sortable: true },
        { field: "followingDriver", label: "Following", visible: true, sortable: false }, // gets cascaded to DriverEdit - 'following' would replace the id from the response
        { field: "id", visible: false },
        { field: "collected", centered: true, label: "Collected", visible: false, sortable: true },
        { field: "totalStops", label: "Stops", visible: true, centered: true, sortable: true },
        { field: "role", label: "Role", visible: true, centered: true }
      ],
    };
  },
  computed: {
    selectedWarehouse: (vm) =>
      vm.filters.warehouseId ? vm.warehouses.find((w) => w.id === vm.filters.warehouseId) : null,
    visibleOnMap: (vm) => vm.$store.state.layers.drivers,
    filteredRoutedDrivers: (vm) => {
      return vm.filteredDriversWithMetata.filter((d) => d.totalStops > 0);
    },
    filteredRoutedDriversExceptSelected: (vm) =>
      vm.filteredRoutedDrivers.filter((d) => d.id !== vm.selectedDriverId),
    filteredDriversWithGeotab: (vm) =>
      vm.driversWithMetadata.filter((d) => d.geotabDriver && d.geotabDriver.device),
    totalCollected: (vm) => {
      const allUnitValues = [];
      vm.driversWithMetadata.forEach((d) => {
        allUnitValues.push(...d.collectedUnitValues);
      });

      return FormatUtil.formatUnitValues(allUnitValues);
    },
    warehouses: (vm) => vm.$store.state.warehouses,
    roles: (vm) => {
      const keyValue = vm.$store.state.drivers.data.reduce((acc, curr) => {
        if (curr.role !== null) {
          const ret = { ...acc };
          ret[curr.role] = curr.role;
          acc = ret;
          return acc;
        }
        return acc
      }, {});

      const keys = Object.keys(keyValue);
      return keys
    },
    loading: (vm) => vm.$store.state.drivers.loading,
    geotabDrivers: (vm) => vm.$store.state.geotabDrivers,
    driversWithMetadata: (vm) => {
      const geotabDrivers = vm.geotabDrivers;

      const mapped = vm.$store.state.drivers.data.map((d) => {
        const stops = d.activeRoute?.stops || [];
        const totalStops = stops.length;
        const completedStops = stops.filter((s) => s.info || s.dropTasks?.some(s => s.info)).length;
        const stopCount = totalStops === 0 ? 0 : `${completedStops}/${totalStops}`;

        const collectedUnitValues = [];
        d.activeRoute.stops.forEach((s) => {
          const info = s.info;
          if (!info) {
            return;
          }

          collectedUnitValues.push({
            unit: info.unit,
            value:
              info.weight0 +
              info.weight1 +
              info.weight2 +
              info.weight3 +
              info.weight4 +
              info.weight5 +
              info.weight6 +
              info.weight7,
          });
        });
        const collected = FormatUtil.formatUnitValues(collectedUnitValues);

        const colorScheme = GeoUtil.randomSchemeFromSeed(d.user.id) || 0;

        const geotabDriver = geotabDrivers.find((v) => v.id === d.geotabId);

        return {
          ...d,
          colorScheme,
          geotabDriver,
          fullName: getDriverName(d.user),
          followingDriver: vm.$store.state.drivers.data.find((fd) => fd.id === d.following),
          warehouseName: d.warehouse.name,
          totalStops,
          stopCount,
          collected,
          collectedUnitValues,
        };
      });

      return mapped;
    },
    driversAtSelectedWarehouseWithStopsWithMetadata: (vm) => {
      return vm.driversWithMetadata.filter((o) => {
        if (Number.isFinite(vm.filters.warehouseId)) {
          if (o.warehouse.id !== vm.filters.warehouseId) {
            return false;
          }
        }

        if (!o.activeRoute.stops.length) {
          return false;
        }

        return true;
      });
    },
    filteredDriversWithMetata: (vm) => {
      const role = vm.filters.role;
      const warehouseId = Number.parseInt(vm.filters.warehouseId);
      const warehouseIdIsFinite = Number.isFinite(warehouseId);

      return vm.driversWithMetadata.filter((o) => {
        if (vm.filters.searchText?.length) {
          let vals = [
            o.user?.userEmail,
            o.user?.firstName,
            o.user?.lastName,
            o.user?.pcUserName,
            o.user?.myAccountInfo?.firstName,
            o.user?.myAccountInfo?.lastName,
          ];
          vals = vals.map((s) => (s ? s.toUpperCase() : s));
          const searchText = vm.filters.searchText.toUpperCase();
          if (!vals.some((v) => v && v.indexOf(searchText) >= 0)) {
            return false;
          }
        }

        if (warehouseIdIsFinite) {
          if (o.warehouse.id !== warehouseId) {
            return false;
          }
        }

        if (role) {
          if (o.role !== role) {
            return false
          }
        }

        return true;
      });
    },
  },
  watch: {
    inActiveTab(val) {
      if (val && this.selectedDriverId) {
        this.$store.dispatch('setSelectedDriver', this.selectedDriverId)
        this.$store.dispatch('exitTpdMode');
      }
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    openVroom() {
      const warehouseId = this.filters.warehouseId;
      if (!Number.isFinite(warehouseId)) {
        return;
      }

      this.$store.dispatch("postVroomForWarehouse", warehouseId);

      // this.showVroom = true
    },
    vroomClosed() {
      this.showVroom = false;
    },
    refresh() {
      this.$store.dispatch("getDrivers");
      this.$store.dispatch("getGeotabDrivers");

      const driver = this.driversWithMetadata.find((d) => d.id == this.selectedDriverId);
      if (driver) {
        this.$mutate(`/dispatcher/routes/${driver.activeRoute.rowPointer}`)

        if (driver.pendingRoute) {
          this.$mutate(`/dispatcher/routes/${driver.pendingRoute.rowPointer}`)
        }
      }
    },
    setShowAssociationWorkflow() {
      this.showAssociationWorkflow = true;
    },
    showAssociationWorkflowClosed() {
      this.refresh();
    },
    searchTextChanged(val) {
      this.filters.searchText = val;
    },
    warehouseChanged(e) {
      const w = e.target.value;
      if (w.length) {
        this.filters.warehouseId = Number.parseInt(w);
      } else {
        this.filters.warehouseId = null;
      }
    },
    roleChanged(e) {
      const w = e.target.value;
      if (w.length) {
        this.filters.role = w;
      } else {
        this.filters.role = null;
      }
    },
    rowOpenedHandler(id) {
      if (this.selectedDriverId === id) {
        return;
      }

      this.selectedDriverId = id;

      const driver = this.driversWithMetadata.find((d) => d.id == id);

      if (driver) {
        this.$store.dispatch("setSelectedDriver", driver.id);
        this.panTo(driver);
      }
    },
    driverClicked(id) {
      this.selectedDriverId = id;
      const driver = this.driversWithMetadata.find((d) => d.id == id);

      if (driver) {
        this.$store.dispatch("setSelectedDriver", driver.id);
        this.panTo(driver);
      }
    },
    panTo(driver) {
      let latLngs = [];
      driver.activeRoute.stops.forEach((s) => {
        latLngs.push(s.location);
      });

      if (driver?.geotabDriver?.device) {
        const device = driver?.geotabDriver?.device;
        latLngs.push({
          lat: device.latitude,
          lng: device.longitude,
        });

        // there is just the vehicle...
        if (latLngs.length === 1) {
          this.panToLatLngs(latLngs);
          return;
        }
      }

      if (driver.warehouse) {
        const ll = GeoUtil.GeoJson.toLatLngs(driver.warehouse.pointGeo);
        latLngs.push(ll);

        // there is just the warehouse...
        if (latLngs.length === 1) {
          this.panToLatLngs(latLngs);
          return;
        }
      }

      if (latLngs.length) {
        this.fitBoundsForLatLngs(latLngs);
      }
    },
    dumpAndClearAllRoutesFromSelectedWarehouse() {
      const driversToClear = this.driversAtSelectedWarehouseWithStopsWithMetadata;

      if (!confirm("Clear This Many Routes? " + driversToClear.length)) {
        return;
      }

      this.$store.dispatch("dumpAndClearDriverRoutes", driversToClear);
    },
  },
};
</script>

<style lang="css" scoped>
.driver-table-wrapper {
  position: relative;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  align-content: stretch;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
}

.filters_rows {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <div class="pickup-location-edit-wrapper has-background-light">
    <div v-if="isLoaded" v-bind:class="{ card: true, 'has-background-danger-light': workingValue.noPickup }">
      <div class="card-header">
        <div class="card-header-title">
          {{ value?.name || "Loading..." }}
        </div>
        <div class="card-header-icon">
          <pan-to-button v-if="value.pointLatLng" :location="value.pointLatLng" />
        </div>
      </div>

      <div class="card-content" style="flex-flow: row; justify-content: space-between; display: flex;">
        <a @click="setShowAddPickupRequestWorkflow">New Pickup Request</a>
        <b-checkbox v-model="workingValue.noPickup" @input="save('noPickup')">Do Not Pickup</b-checkbox>
        <b-checkbox v-model="workingValue.anyWeatherOk" @input="save('anyWeatherOk')">Route in Any Weather</b-checkbox>
        <a @click="trimBounds">Trim Bounds</a>
      </div>

      <div class="card-content">
        <div class="content">
          <b-message v-if="value.isArchived" size="is-small" has-icon icon="lock">
            This pickup location is archived and cannot be changed.
          </b-message>
          <p>
            {{
              `${value.addStreet}, ${value.addCity}, ${value.addState}, ${value.addCounty}
            ${value.addPostalCode},${value.addCountry}`
            }}
          </p>
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <individual-field v-slot="inputProps" :enabled="canEdit" label="Name" @onSave="save('name')"
              @onReset="reset('name')">
              <input :disabled="inputProps.disabled" v-model="workingValue.name" />
            </individual-field>

            <div class="mt-1">

              <UserSelect v-model="workingValue.ownerId" label="Owner" @save="save('ownerId', $event)"
                @reset="reset('ownerId')">
                <template #left>
                  <icon-button @click="notifyOwner" :disabled="!value?.owner?.pfpuExpoNotificationToken"
                    icon="cellphone-sound" class="mr-1" title="Notify" />
                </template>
              </UserSelect>
            </div>
            <!-- <individual-field v-slot="inputProps" :enabled="canEdit" label="Owner" @onSave="save('ownerId')"
              @onReset="reset('ownerId')">
              
              <input v-if="inputProps.disabled" :disabled="inputProps.disabled" :value="computedOwnerName" />
              <div v-if="!inputProps.disabled">
                <input list="owners" name="owner" v-model="workingValue.ownerId" required />
                <datalist id="owners">
                  <option disabled value="">Please select one</option>
                  <option v-for="(user, index) in users" :key="index" :value="user.id">
                    {{ getFullNameAny(user) }}
                  </option>
                </datalist>
              </div>
            </individual-field> -->

            <div v-if="value?.containers?.length" class="mt-1">
              Containers: {{ value.containers.map((c) => c.name).join(", ") }}
            </div>
            <div v-else class="mt-1">
              No Containers
            </div>

            <ToggleNotes :data="workingValue.notes" :clearNotes="!this.value.notes.length" title="Location Notes"
              :editable="true" @saveEvent="save('notes', $event)" class="mt-2 mb-2" />
            <div v-if="value.imageId" class="mb-2">
              Primary Image:
              <images-carousel :images="primaryImage" tooltipPosition="is-right" :action="true"
                actionTitle="Make Primary" />
              <!-- @executeAction="updatePrimaryImage($event)" -->
            </div>

            <div v-if="value?.images?.length" class="mt-1">
              Recent Location Images:
              <images-carousel :images="recentLocationImages" tooltipPosition="is-right" :action="true"
                actionTitle="Make Primary" @executeAction="updatePrimaryImage($event)" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="value?.driverStops" class="ml-1 mr-1">
        <div class="is-uppercase has-text-weight-bold mt-3">
          Driver Stop History {{ driverStopsPaginationStatus }}
        </div>
        <driver-stops-list :stops="driverStops" />
      </div>

      <div v-if="value?.pickupRequests" class="ml-1 mr-1 mb-1">
        <div class="is-uppercase has-text-weight-bold mt-3">
          Pickup Request History {{ pickupRequestsPaginationStatus }}
        </div>
        <pickup-requests-list class="" :requests="value.pickupRequests || []" />
      </div>

      <div v-if="deleteEnabled" class="mt-3">
        <a class="delete-location has-background-dark has-text-warning is-large is-clickable mr-1 p-2"
          @click="deletePickupLocation">
          Delete Location
          <i class="fas fa-lg fa-trash-alt" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div v-else>
      <b-icon pack="mdi" icon="sync" size="is-large" custom-class="spin"> </b-icon>
    </div>

    <!-- Add stop for selected driver. -->
    <div style="
        display: flex;
        flex-direction: row;
        max-width: 100%;
        justify-content: flex-end;
        margin-top: 10px;
      " v-if="!pickupLocationOnSelectedDriverActiveRoute">
      <b-button v-if="selectedDriver" :disabled="!canAddToRoute" size="is-small" @click="addStopForDriver">
        Add for <User :value="selectedDriver.user" :isDriver="true" :clickable="false"/> (Ctrl-A)</b-button>
    </div>

    <PickupLocation v-if="(pickupLocationToShow) && (!editingBounds)" :pickupLocation="pickupLocationToShow"
      :selected="true" @dblclick="beginEditBounds()" />

    <edit-pickup-location-bounds v-if="value && editingBounds" :pickupLocation="value" @done="doneEditingBounds" />

    <b-modal v-model="showAddPickupRequestWorkflow" width="auto" :full-screen="false"
      @close="showAddPickupRequestWorkflowClosed">
      <add-pickup-request-workflow v-if="value" @close="showAddPickupRequestWorkflowClosed" :pickupLocation="value" />
    </b-modal>

    <b-loading :is-full-page="true" :modelValue="busy" ></b-loading>
  </div>
</template>

<script>
import hotkeys from "hotkeys-js";

import User from "@/components/common/User.vue";
import PanToButton from "@/components/common/PanToButton";
import AddPickupRequestWorkflow from "@/components/common/AddPickupRequestWorkflow";
import IndividualField from "@/components/IndividualField.vue";
import PageLoader from "@/components/common/PageLoader";
import ToggleNotes from "@/components/common/ToggleNotes.vue";
import { getFullNameAny } from "@/services/UserUtil";
import PickupRequestsList from "@/components/maps/PickupRequestsList";
import DriverStopsList from "./DriverStopsList.vue";
import IconButton from "@/components/common/IconButton.vue";
import ImagesCarousel from "@/components/common/ImagesCarousel.vue";
import ImageButton from "@/components/common/ImageButtonSet.vue";
import PickupLocation from "@/components/maps/PickupLocation";
import EditPickupLocationBounds from "@/components/maps/EditPickupLocationBounds";
import { useApi } from "@/services/apiService";

export default {
  props: {
    startingValue: {
      type: Object,
      required: true,
    },
  },
  components: {
    PanToButton, User,
    PageLoader,
    PickupLocation,
    EditPickupLocationBounds,
    IndividualField,
    PickupRequestsList,
    DriverStopsList,
    ToggleNotes,
    AddPickupRequestWorkflow,
    IconButton,
    ImagesCarousel,
    ImageButton,
  },
  model: {
    prop: "value",
    event: "change",
  },
  inject: ["tabsData", "inActiveTab"],
  data: function () {
    return {
      busy: false,
      editingBounds: false,
      getFullNameAny,
      key: new Date().getTime().toString(),
      value: null,
      showAddPickupRequestWorkflow: false,
      workingValue: {
        id: 0,
        rollCount: 0,
        name: "",
        ownerId: 0,
        notes: "",
        noPickup: false,
        anyWeatherOk: false
      },
      tabTitle: "Pickups",
    };
  },
  computed: {
    recentLocationImages: vm => {
      const sorted = vm.value.images.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
      
      return sorted.slice(0, 10)
    },
    isLoaded: (vm) => vm.value && vm.workingValue.id,
    driverStops: (vm) => vm.value?.driverStops || [],
    completedDriverStops: (vm) => vm.driverStops.filter((s) => s.info),
    pickupRequests: (vm) => vm.value?.pickupRequests || [],
    deleteEnabled: (vm) =>
      vm.value?.driverStops?.length === 0 && vm.value?.pickupRequests?.length == 0,
    driverStopsPaginationStatus: (vm) => vm.paginationStatus(vm.value?.driverStops),
    pickupRequestsPaginationStatus: (vm) => vm.paginationStatus(vm.value?.pickupRequests),
    users(vm) {
      return vm.$store.getters.users;
    },
    primaryImage: (vm) => {
      return vm.value?.images.filter((v) => v.id == vm.value.imageId);
    },
    pickupLocationToShow: vm => {
      const ret = vm.value || vm.startingValue
      return ret;
    },
    pickupLocationOnSelectedDriverActiveRoute: (vm) => {
      const driver = vm.selectedDriver;
      if (!driver) {
        return null;
      }

      if (!vm.value) {
        return null;
      }

      const route = driver.activeRoute;

      return route.stops.some((s) => s.pickupLocationId === vm.value.id);
    },
    selectedDriver: (vm) => vm.$store.state.selectedDriver,
    canAddToRoute: (vm) => vm.isLoaded && vm.selectedDriver && !vm.workingValue.noPickup,
    addStopHotkey: () => "ctrl+a",
    formattedAddress: (vm) => {
      const value = vm.value;

      if (!value.addStreet || value.addStreet === "undefined") {
        return value.addCity + ", " + value.addState;
      }

      return value.addStreet + "<br>" + value.addCity + ", " + value.addState;
    },
    computedOwnerName: (vm) => {
      const owner = vm.value.owner
      if (!owner) {
        return null;
      }

      return getFullNameAny(owner);
    },
    canEdit: (vm) => {
      return !vm.value.isArchived;
    },
  },
  watch: {
    startingValue: "refresh",
    inActiveTab(isActive) {
        if (!isActive) {
          hotkeys.unbind(this.addStopHotkey, this.tab);
        } else if (this.canAddToRoute && isActive) {
          this.setHotkey(this.addStopHotkey, this.addStopForDriver);
        }
    },
    canAddToRoute(newValue) {
      if (newValue) this.setHotkey(this.addStopHotkey, this.addStopForDriver);
      else {
        hotkeys.unbind(this.addStopHotkey, this.tab);
      }
    },
  },
  mounted() {
    this.refresh();

    if (this.inActiveTab) {
      if (this.canAddToRoute) {
        this.setHotkey(this.addStopHotkey, this.addStopForDriver);
      } else hotkeys.unbind(this.addStopHotkey, this.tabTitle);

      hotkeys.setScope(this.tabTitle);
    }
  },
  methods: {
    async trimBounds() {
      try {
        this.busy = true

        await useApi().postLocationBoundsFix(this.startingValue.rowPointer)

        await this.refresh();
      }
      catch (e) {
        alert(e?.response?.data || e?.message || e)
      }
      finally {
        this.busy = false
      }
    },
    doneEditingBounds() {
      this.editingBounds = false;
      this.refresh();
    },
    beginEditBounds() {
      if (this.$store.state.mapActivityActive) {
        return;
      }

      this.editingBounds = true;
    },
    /**
     * @param {string} binding
     * @param {Function} action
     */
    setHotkey(binding, action) {
      hotkeys.unbind(this.addStopHotkey, this.tabTitle);
      hotkeys(binding, this.tabTitle, (e) => {
        e.stopPropagation();
        e.preventDefault();

        action();
      });
    },
    updatePrimaryImage(image) {
      this.$store
        .dispatch("updatePrimaryImage", { ...image })
        .then(this.refresh());
    },
    addStopForDriver() {
      this.$store.dispatch("addPickupLocationForSelectedDriver", this.startingValue.rowPointer);
    },
    setShowAddPickupRequestWorkflow() {
      this.showAddPickupRequestWorkflow = true;
    },
    showAddPickupRequestWorkflowClosed(event) {
      this.showAddPickupRequestWorkflow = false;

      if (event?.success === true) {
        this.refresh();
      }
    },
    paginationStatus(o) {
      if (!o?.fetched) {
        return "";
      }

      if (!o.total) {
        return "(None)";
      }

      return `${o.value.length}/${o.total}`;
    },
    async deletePickupLocation() {
      if (!confirm("Delete Location?", "All Related Pickup Requests Will Be Removed")) {
        return;
      }

      await this.$store.dispatch("deletePickupLocation", this.startingValue.rowPointer);
      await this.$store.dispatch("updatePaginatedPickupLocations");
    },
    async refresh() {
      try {
        this.value = null;
        this.editingBounds = false;

        this.value = await this.$store.getters["fullPickupLocation"](this.startingValue.rowPointer);

        for (let key of Object.keys(this.workingValue)) {
          this.workingValue[key] = this.value[key];
          if (key == "notes" && !this.value[key].length) {
            this.workingValue[key] = "no notes for this location.";
          }
        }
      } catch (e) {
        this.$store.dispatch("error", e);
      }
    },
    async save(prop, value) {
      const saveObject = {
        rowPointer: this.startingValue.rowPointer,
      };

      switch (prop) {
        default:
          this.workingValue.noPickup = !this.workingValue.noPickup;
          this.workingValue.anyWeatherOk = !this.workingValue.anyWeatherOk;
          saveObject[prop] = this.workingValue[prop];
          break;

        case "ownerId":
          saveObject[prop] = isFinite(value) ? value : null;
          break
      }

      if (saveObject.notes) {
        saveObject.notes = value ? value : "";
      }

      this.$store.dispatch("putPickupLocation", saveObject).then(this.refresh);
    },
    reset(prop) {
      this.workingValue[prop] = this.value[prop];
    },
    notifyOwner() {
      const message = prompt("Enter message you would like to send");

      if (message == null || message.length == 0) return;

      const notification = {
        userId: this.value.owner.userId,
        app: "pfpu",
        title: `Message from Revolution Plastics`,
        body: message,
      };

      if (!confirm("Notify Owner?" + "\n\n" + message)) {
        return;
      }

      this.$store.dispatch("postNotification", notification);
    },
  },
};
</script>

<style lang="css" scoped>
.pickup-location-edit-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px !important;
}

:deep(.field-label) {
  margin-right: 0.5rem !important;
}

:deep(.field) {
  margin-bottom: 0rem !important;
}

:deep(.label) {
  margin-bottom: 0.15rem;
}

:deep(textarea) {
  display: inline;
}
</style>

<template>
  <div class="tabs-wrapper">
    <div class="tabs-headers">
      <span
        v-for="(tab, index) in tabs"
        :key="tab.title"
        :class="{
          'tabs-header': 1,
          'is-active': data.selectedTab === tab.title ? 1 : 0,
        }"
        @click="setSelectedTab(tab.title)"
      >
        {{ tab.title }}
      </span>
    </div>

    <template v-for="tab in tabs" :key="tab.slotName">
      <slot :name="tab.slotName" />
    </template>
  </div>
</template>

<script setup>
import { computed, useSlots, provide, reactive } from "vue";

const props = defineProps({
  titleMap: {
    required: false,
    default: {},
    type: Object,
  },
});

const slots = useSlots();
const tabs = computed(() => {
  const slotNames = Object.keys(slots);

  return slotNames.map((name) => {
    return {
      title: props.titleMap[name],
      slotName: name,
    };
  });
});

const data = reactive({
  selectedTab: null,
  tabsData: Object.values(props.titleMap).map(title => {
    return {
      title,
      isActive: false,
      wasEverActive: false,
    };
  })
});
//To pass in provide needs to be computed
const computedData = computed(()=>{
  return data.tabsData
})

provide("tabsData", computedData);


function setSelectedTab(title) {
  data.selectedTab = title;
  data.tabsData = data.tabsData.map((tab) => ({
    ...tab,
    isActive: tab.title === title,
    wasEverActive: tab.wasEverActive || tab.title === title,
  }));
}
</script>

<style lang="css" scoped>
.tabs-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex-grow: 1;
  height: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.tabs-headers {
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: solid 1px;
  color: gray
}

.tabs-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: grey;
  justify-self: center;
  flex-grow: 1;
  text-align: center;
  padding: 0.5em;
}

.tabs-header.is-active {
  background-color: var(--primary);
  color: white !important;
  font-weight: bold;
  opacity: 1;
}
</style>

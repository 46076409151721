<template>
  <div class="tab-wrapper" v-show="tabData?.isActive">
    <slot v-if="tabData?.wasEverActive"></slot>
    <div v-if="!tabData">Tab Data Not Found for: " + {{ title }}</div>
  </div>
</template>

<script setup>
import { inject, provide, computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: "Tab",
  },
})

const tabsData = inject("tabsData");



const tabData = computed(() => {
  return tabsData.value.find((tab) => tab.title === props.title);
})

const inActiveTab = computed(() => {
  return tabData?.value?.isActive
})

provide("inActiveTab", inActiveTab)

</script>

<style>
.tab-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}
</style>

<template>
  <div class="driver-table-wrapper">
    <Table
      @rowOpened="rowOpenedHandler"
      :data="rowsWithMetadata"
      :columns="data.columns"
      :multipleDetails="false"
      detailKey="id"
      :loading="swr.isValidating.value"
      :snapTo="data.selectedRowId"
      :controls="true"
    >
      <template #controls>
        <filters :showTooltip="false">
          <InclusiveFilter
            name="Completed"
            :loading="swr.isValidating.value"
            :value="tableFilters"
            @change="updateFilter($event)"
          />
        </filters>

        <b-button
          @click="refresh"
          size="is-small"
          icon-right="refresh"
          title="Refresh Table"
          class="mb-1"
        />
      </template>

      <template #detail="props">
        <TpdPickupRequest :data="props.value"/>
        <!-- {{ props }} -->
      </template>
      <template #location="{ value }">
        <LocationToolTip :location="value.location" />
      </template>
    </Table>
  </div>
</template>

<script setup>
import { reactive, computed, watch, inject } from "vue";
import useSwr from "swrv";

import Table from "@/components/common/Table";
import Filters from "@/components/common/Filters.vue";
import { useStore } from "vuex";
import TpdPickupRequest from "../../../../components/maps/TpdPickupRequest.vue";
import InclusiveFilter from "../../../../components/common/InclusiveFilter.vue";
import LocationToolTip from "../../../../components/common/LocationToolTip.vue";

const data = reactive({
  queryParams: {},
  columns: [
    { field: "freightId", label: "Freight ID", visible: true, sortable: true },
    { field: "location", label: "Location", visible: true, sortable: true },
    { field: "createdOn", label: "Created", visible: true, sortable: true },
    { field: "completedOn", label: "Completed", visible: true, sortable: true },
  ],
  selectedRowId: null,
});

const pagination = reactive({
  total: 0,
  page: data.queryParams.page,
  perPage: data.queryParams.perPage,
});

const inActiveTab = inject("inActiveTab");
const $api = inject("$api");
const $store = useStore();

const swrUrl = computed(() => {
  const params = new URLSearchParams(data.queryParams);
  const queryString = params.toString();

  if (queryString.length) {
    $store.dispatch("updateTpdSwrUrl", `/dispatcher/tpd-pickup-requests?${queryString}&perPage=30`);
    return `/dispatcher/tpd-pickup-requests?${queryString}&perPage=30`;
  }
  $store.dispatch("updateTpdSwrUrl", `/dispatcher/tpd-pickup-requests?perPage=30`);
  return `/dispatcher/tpd-pickup-requests?perPage=30`;
});

const tableFilters = computed(() => $store.state.tpdTableFilters);
const swr = useSwr(swrUrl, $api.fetch, {
  revalidateOnFocus: false,
});
const rowsWithMetadata = computed(() => {
  const rows = swr.data?.value?.results || [];
  return rows.map((row) => ({
    ...row,
    createdOn: row.createdOn ? new Date(row.createdOn).toLocaleString() : "",
    completedOn: row.completedOn
      ? new Date(row.completedOn).toLocaleDateString("en-US")
      : "",
    location: {
      state: row.pickupRequest.pickupLocation.addState,
      city: row.pickupRequest.pickupLocation.addCity,
      county: row.pickupRequest.pickupLocation.addCounty,
      zip: row.pickupRequest.pickupLocation.addPostalCode,
      street: row.pickupRequest.pickupLocation.addStreet,
    },
  }));
});

function rowOpenedHandler(id) {
  data.selectedRowId = id;
}

function refresh() {
  swr.mutate();
}

function updateFilter(event) {
  $store.dispatch("updateTpdTableFilters", event);
  swr.mutate();
}

watch(
  inActiveTab,
  (val) => {
    if (val) {
      //   $store.dispatch("setSelectedDriver", null)
      $store.dispatch("enterTpdMode");
    }
  },
  { immediate: true }
);

watch(swr.data, () => {
  pagination.total = swr.data.value?.count || 0;
});

watch(tableFilters, (val) => {
  if (val.includes("inc")) {
    data.queryParams.completed = true;
  } else if (val.includes("exc")) {
    data.queryParams.completed = false;
  } else {
    delete data.queryParams.completed;
  }
  swr.mutate();
});

// export default {
//     components: {
//         Table,
//         User,
//         Filters,
//     },
//     inject: ["$mutate"],
//     data() {
//         return {
//             getDriverName,
//             showAssociationWorkflow: false,
//             showVroom: false,
//             selectedDriverId: null,
//             filters: {
//                 role: null,
//                 warehouseId: null,
//                 searchText: "",
//             },
//             columns: [
//                 { field: "fullName", label: "Name", visible: true, sortable: true },
//                 { field: "followingDriver", label: "Following", visible: true, sortable: false }, // gets cascaded to DriverEdit - 'following' would replace the id from the response
//                 { field: "id", visible: false },
//                 { field: "collected", centered: true, label: "Collected", visible: false, sortable: true },
//                 { field: "totalStops", label: "Stops", visible: true, centered: true, sortable: true },
//                 { field: "role", label: "Role", visible: true, centered: true }
//             ],
//         };
//     },
//     computed: {
//         selectedWarehouse: (vm) =>
//             vm.filters.warehouseId ? vm.warehouses.find((w) => w.id === vm.filters.warehouseId) : null,
//         visibleOnMap: (vm) => vm.$store.state.layers.drivers,
//         filteredRoutedDrivers: (vm) => {
//             return vm.filteredDriversWithMetata.filter((d) => d.totalStops > 0);
//         },
//         filteredRoutedDriversExceptSelected: (vm) =>
//             vm.filteredRoutedDrivers.filter((d) => d.id !== vm.selectedDriverId),
//         filteredDriversWithGeotab: (vm) =>
//             vm.driversWithMetadata.filter((d) => d.geotabDriver && d.geotabDriver.device),
//         totalCollected: (vm) => {
//             const allUnitValues = [];
//             vm.driversWithMetadata.forEach((d) => {
//                 allUnitValues.push(...d.collectedUnitValues);
//             });

//             return FormatUtil.formatUnitValues(allUnitValues);
//         },
//         warehouses: (vm) => vm.$store.state.warehouses,
//         roles: (vm) => {
//             const keyValue = vm.$store.state.drivers.data.reduce((acc, curr) => {
//                 if (curr.role !== null) {
//                     const ret = { ...acc };
//                     ret[curr.role] = curr.role;
//                     acc = ret;
//                     return acc;
//                 }
//                 return acc
//             }, {});

//             const keys = Object.keys(keyValue);
//             return keys
//         },
//         loading: (vm) => vm.$store.state.drivers.loading,
//         geotabDrivers: (vm) => vm.$store.state.geotabDrivers,
//         driversWithMetadata: (vm) => {
//             const geotabDrivers = vm.geotabDrivers;

//             const mapped = vm.$store.state.drivers.data.map((d) => {
//                 const stops = d.activeRoute?.stops || [];
//                 const totalStops = stops.length;
//                 const completedStops = stops.filter((s) => s.info || s.dropTasks?.some(s => s.info)).length;
//                 const stopCount = totalStops === 0 ? 0 : `${completedStops}/${totalStops}`;

//                 const collectedUnitValues = [];
//                 d.activeRoute.stops.forEach((s) => {
//                     const info = s.info;
//                     if (!info) {
//                         return;
//                     }

//                     collectedUnitValues.push({
//                         unit: info.unit,
//                         value:
//                             info.weight0 +
//                             info.weight1 +
//                             info.weight2 +
//                             info.weight3 +
//                             info.weight4 +
//                             info.weight5 +
//                             info.weight6 +
//                             info.weight7,
//                     });
//                 });
//                 const collected = FormatUtil.formatUnitValues(collectedUnitValues);

//                 const colorScheme = GeoUtil.randomSchemeFromSeed(d.user.id) || 0;

//                 const geotabDriver = geotabDrivers.find((v) => v.id === d.geotabId);

//                 return {
//                     ...d,
//                     colorScheme,
//                     geotabDriver,
//                     fullName: getDriverName(d.user),
//                     followingDriver: vm.$store.state.drivers.data.find((fd) => fd.id === d.following),
//                     warehouseName: d.warehouse.name,
//                     totalStops,
//                     stopCount,
//                     collected,
//                     collectedUnitValues,
//                 };
//             });

//             return mapped;
//         },
//         filteredDriversWithMetata: (vm) => {
//             const role = vm.filters.role;
//             const warehouseId = Number.parseInt(vm.filters.warehouseId);
//             const warehouseIdIsFinite = Number.isFinite(warehouseId);

//             return vm.driversWithMetadata.filter((o) => {
//                 if (vm.filters.searchText?.length) {
//                     let vals = [
//                         o.user?.userEmail,
//                         o.user?.firstName,
//                         o.user?.lastName,
//                         o.user?.pcUserName,
//                         o.user?.myAccountInfo?.firstName,
//                         o.user?.myAccountInfo?.lastName,
//                     ];
//                     vals = vals.map((s) => (s ? s.toUpperCase() : s));
//                     const searchText = vm.filters.searchText.toUpperCase();
//                     if (!vals.some((v) => v && v.indexOf(searchText) >= 0)) {
//                         return false;
//                     }
//                 }

//                 if (warehouseIdIsFinite) {
//                     if (o.warehouse.id !== warehouseId) {
//                         return false;
//                     }
//                 }

//                 if (role) {
//                     if (o.role !== role) {
//                         return false
//                     }
//                 }

//                 return true;
//             });
//         },
//     },
//     mounted() {
//         this.$store.dispatch("setSelectedDriver", null)
//         this.refresh();
//     },
//     methods: {
//         openVroom() {
//             const warehouseId = this.filters.warehouseId;
//             if (!Number.isFinite(warehouseId)) {
//                 return;
//             }

//             this.$store.dispatch("postVroomForWarehouse", warehouseId);

//             // this.showVroom = true
//         },
//         vroomClosed() {
//             this.showVroom = false;
//         },
//         refresh() {
//             this.$store.dispatch("getDrivers");
//             this.$store.dispatch("getGeotabDrivers");

//             const driver = this.driversWithMetadata.find((d) => d.id == this.selectedDriverId);
//             if (driver) {
//                 this.$mutate(`/dispatcher/routes/${driver.activeRoute.rowPointer}`)

//                 if (driver.pendingRoute) {
//                     this.$mutate(`/dispatcher/routes/${driver.pendingRoute.rowPointer}`)
//                 }
//             }
//         },
//         setShowAssociationWorkflow() {
//             this.showAssociationWorkflow = true;
//         },
//         showAssociationWorkflowClosed() {
//             this.refresh();
//         },
//         searchTextChanged(val) {
//             this.filters.searchText = val;
//         },
//         warehouseChanged(e) {
//             const w = e.target.value;
//             if (w.length) {
//                 this.filters.warehouseId = Number.parseInt(w);
//             } else {
//                 this.filters.warehouseId = null;
//             }
//         },
//         roleChanged(e) {
//             const w = e.target.value;
//             if (w.length) {
//                 this.filters.role = w;
//             } else {
//                 this.filters.role = null;
//             }
//         },
//         rowOpenedHandler(id) {
//             if (this.selectedDriverId === id) {
//                 return;
//             }

//             this.selectedDriverId = id;

//             const driver = this.driversWithMetadata.find((d) => d.id == id);

//             if (driver) {
//                 this.$store.dispatch("setSelectedDriver", driver.id);
//                 this.panTo(driver);
//             }
//         },
//         driverClicked(id) {
//             this.selectedDriverId = id;
//             const driver = this.driversWithMetadata.find((d) => d.id == id);

//             if (driver) {
//                 this.$store.dispatch("setSelectedDriver", driver.id);
//                 this.panTo(driver);
//             }
//         },
//         panTo(driver) {
//             let latLngs = [];
//             driver.activeRoute.stops.forEach((s) => {
//                 latLngs.push(s.location);
//             });

//             if (driver?.geotabDriver?.device) {
//                 const device = driver?.geotabDriver?.device;
//                 latLngs.push({
//                     lat: device.latitude,
//                     lng: device.longitude,
//                 });

//                 // there is just the vehicle...
//                 if (latLngs.length === 1) {
//                     this.panToLatLngs(latLngs);
//                     return;
//                 }
//             }

//             if (driver.warehouse) {
//                 const ll = GeoUtil.GeoJson.toLatLngs(driver.warehouse.pointGeo);
//                 latLngs.push(ll);

//                 // there is just the warehouse...
//                 if (latLngs.length === 1) {
//                     this.panToLatLngs(latLngs);
//                     return;
//                 }
//             }

//             if (latLngs.length) {
//                 this.fitBoundsForLatLngs(latLngs);
//             }
//         },
//         dumpAndClearAllRoutesFromSelectedWarehouse() {
//             const driversToClear = this.driversAtSelectedWarehouseWithStopsWithMetadata;

//             if (!confirm("Clear This Many Routes? " + driversToClear.length)) {
//                 return;
//             }

//             this.$store.dispatch("dumpAndClearDriverRoutes", driversToClear);
//         },
//     },
// };
</script>

<style lang="css" scoped>
.driver-table-wrapper {
  position: relative;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  align-content: stretch;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-width: 550px;
}

.filters_rows {
  display: flex;
  flex-direction: row;
}
</style>

<template>
  <div class="card" :class="{ success: status === 'Completed' }">
    <div
      class="card-header header"
      :class="{
        pending: status === 'Pending',
        assigned: status === 'Assigned',
      }"
    >
      <span>
        Status: {{ status }}
        <i
          v-if="status === 'Completed'"
          class="mdi mdi-check-circle mr-1"
          aria-hidden="true"
        ></i>
      </span>
      <span>
        {{
          formatUnitValues([
            {
              unit: props.data.pickupRequest.unit,
              value: props.data.pickupRequest.unitValue,
            },
          ])
        }}
        ({{ formatMaterial(props.data.pickupRequest) }})
      </span>
    </div>
    <div class="card-content body">
      <IndividualField
        v-slot="freightId"
        label="Freight ID"
        :enabled="status !== 'Completed'"
        @onSave="save({ freightId: requestData.freightId })"
        @onReset="resetField('freightId')"
      >
        <input :disabled="freightId.disabled" v-model="requestData.freightId" />
      </IndividualField>
      <IndividualField
        v-slot="deliveredLbs"
        label="Delivered Lbs"
        :enabled="status !== 'Completed'"
        @onSave="save({ deliveredLbs: requestData.deliveredLbs })"
        @onReset="resetField('deliveredLbs')"
      >
        <input
          :disabled="deliveredLbs.disabled"
          v-model="requestData.deliveredLbs"
        />
      </IndividualField>
      <IndividualField
        v-slot="deliveredPallets"
        label="Delivered Pallets"
        :enabled="status !== 'Completed'"
        @onSave="save({ deliveredPallets: requestData.deliveredPallets })"
        @onReset="resetField('deliveredPallets')"
      >
        <input
          :disabled="deliveredPallets.disabled"
          v-model="requestData.deliveredPallets"
        />
      </IndividualField>

      <b-field label="Select Completed Date">
        <b-datepicker
          v-model="requestData.completedOn"
          placeholder="Pick a date"
          icon="calendar-today"
          size="is-small"
          :append-to-body="true"
          :icon-right="requestData.completedOn ? 'close-circle' : ''"
          :icon-right-clickable="status !== 'Completed'"
          @icon-right-click="clearDate"
          :disabled="status === 'Completed'"
        >
        </b-datepicker>
      </b-field>

      <div class="tpd-pickup-request">
        <span>Estimated Lbs:{{ props.data.estimatedLbs }}</span>
        <span>Estimated Pallets: {{ props.data.estimatedPallets }}</span>
      </div>
    </div>

    <div v-if="status !== 'Completed'" class="button-group">
      <b-button
        v-if="!props.data.freightId"
        @click="removeRequest"
        type="is-danger"
        icon-left="delete"
        size="is-small"
        >Delete Request</b-button
      >
      <b-button
        v-if="isFormComplete"
        @click="confirmRequest"
        type="is-success"
        icon-left="check"
        size="is-small"
        >Confirm Request</b-button
      >
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import { formatUnitValues, formatMaterial } from "@/services/FormatUtil";
import IndividualField from "@/components/IndividualField.vue";
import { useStore } from "vuex";
const $store = useStore();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const requestData = reactive({
  freightId: props.data.freightId || "",
  deliveredLbs: props.data.deliveredLbs || "",
  deliveredPallets: props.data.deliveredPallets || "",
  completedOn: props.data.completedOn ? new Date(props.data.completedOn) : null,
});

const status = computed(() => {
  if (props.data.completedOn.length > 1) return "Completed";
  return props.data.freightId ? "Assigned" : "Pending";
});
//as long as all data is comes from db except completedOn
const isFormComplete = computed(() => {
  return (
    !!props.data.freightId &&
    props.data.deliveredLbs >= 0 &&
    props.data.deliveredPallets >= 0 &&
    requestData.completedOn
  );
});

function save(updatedFields) {
  $store.dispatch("updateTpdPickupRequest", {
    rowPointer: props.data.rowPointer,
    updateData: updatedFields,
    swrUrl: $store.state.tpdSwrUrl,
  });
}

function saveDate() {
  $store.dispatch("updateTpdPickupRequest", {
    rowPointer: props.data.rowPointer,
    updateData: { completedOn: requestData.completedOn },
    swrUrl: $store.state.tpdSwrUrl,
  });
}
function resetField(field) {
  requestData[field] = props.data[field];
}
function removeRequest() {
  $store.dispatch("deleteTpdPickupRequest", {
    rowPointer: props.data.rowPointer,
    swrUrl: $store.state.tpdSwrUrl,
  });
}
//TODO: Close pickup request
function confirmRequest() {
  saveDate();
}
function clearDate() {
  requestData.completedOn = null;
}
</script>
<style lang="css" scoped>
.header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
.body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 100%;
}
.tpd-pickup-request {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.button-group {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
.success {
  color: var(--successDark);
  background: var(--successLight);
}
.pending {
  background: var(--pendingLight);
  color: var(--pendingDark);
}

.assigned {
  background: var(--assignedLight);
  color: var(--assignedDark);
}
</style>

<template>
  <div class="wrapper">
    <Table
      :search="true"
      @search="searchTextChanged($event)"
      :controls="true"
      :data="rows"
      detailKey="rowPointer"
      :pagination="rowPagination"
      @pageChange="pageChange"
      @refresh="refresh"
      :showRefresh="true"
      :columns="columns"
      :loading="rowPagination.loading"
      :snapTo="selectedRowRowPointer"
      @rowOpened="rowOpenedHandler"
    >
      <template #controls>
        <filters>
          <template #filters>
            <div class="filters_columns">
              <div class="filters_rows">
                <!-- row one filters -->
                <inclusive-filter
                  :name="'Routed'"
                  :value="filters.routed"
                  @change="updateFilter('routed', $event)"
                  :loading="rowPagination.loading"
                />
                <inclusive-filter
                  :name="'Completed'"
                  :value="filters.completed"
                  @change="updateFilter('completed', $event)"
                  class="ml-1"
                  :loading="rowPagination.loading"
                />
                <inclusive-filter
                  name="New Users"
                  :value="filters.newUsers"
                  @change="updateFilter('newUsers', $event)"
                  class="ml-1"
                  :loading="rowPagination.loading"
                />
                <inclusive-filter
                  name="Owned"
                  :value="filters.owned"
                  @change="updateFilter('owned', $event)"
                  class="ml-1"
                  :loading="rowPagination.loading"
                />

                <inclusive-filter
                  name="Associated"
                  v-if="filters.owned.includes('inc')"
                  :value="filters.associated"
                  @change="updateFilter('associated', $event)"
                  class="ml-1"
                  :loading="rowPagination.loading"
                />

                <bbox-filter
                  :value="filters.bbox"
                  @change="updateFilter('bbox', $event)"
                  class="ml-1"
                />
              </div>
              <div style="display: flex;">
                <div>
                  <!-- row two filters -->
                  <div class="filters_rows mt-2">
                    <div>
                      <label
                        for="pickup_location_plastic_type_group_id"
                        class="mr-1"
                        >Group:</label
                      >
                      <select
                        id="pickup_location_plastic_type_group_id"
                        @change="plasticTypeGroupIdChanged"
                      >
                        <option value="" selected>All</option>
                        <option
                          v-for="ptg in plasticTypeGroups"
                          :key="`plastictypegroupoption:${ptg.id}`"
                          :value="ptg.id"
                        >
                          {{ ptg.name }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="pickup_location_plastic_type_id"
                        class="mr-1 ml-3"
                        >Type:</label
                      >
                      <select
                        id="pickup_location_plastic_type_id"
                        @change="plasticTypeIdChanged"
                        :multiple="plasticTypeMulti ? true : undefined"
                        :class="
                          plasticTypeMulti
                            ? expandPlasticTypeMulti
                              ? 'expanded-multi'
                              : 'multi'
                            : ''
                        "
                      >
                        <option v-if="!plasticTypeMulti" value="" selected>
                          All
                        </option>

                        <option
                          v-for="pt in plasticTypes"
                          :key="`plastictypeoption:${pt.id}`"
                          :value="pt.id"
                        >
                          {{ pt.name }}
                        </option>
                      </select>
                    </div>
                    <button
                      class="ml-3"
                      @click="togglePlasticTypeMulti"
                      style="align-self: flex-start"
                    >
                      🛠︎
                    </button>
                    <button
                      v-if="plasticTypeMulti"
                      @click="toggleExpandPlasticTypeMulti"
                      style="align-self: flex-start"
                    >
                      {{ expandPlasticTypeMulti ? "⬆️" : "⬇️" }}
                    </button>
                  </div>

                  <!-- row three filters -->
                  <div class="align_flex mt-2" style="justify-content: space-between;">
                    <div class="filters_rows">
                      <label class="mr-1" for="pickup-request-user-search"
                        >User:
                      </label>
                      <input
                        id="pickup-request-user-search"
                        :disabled="rowPagination.loading"
                        type="text"
                        @change="
                          updateFilter('ownerSearchText', $event.target.value)
                        "
                      />
                    </div>
                    
                    <button
                      @click="toggleShowSummary"
                      class="ml-1"
                      style="width: 50%;"
                    >
                      {{ showSummary ? "Hide Summary" : "Show Summary" }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- row four table-->
              <div
                v-show="resourceSummary.length && showSummary"
                style="width: 60%; padding: 0.5em"
              >
                <table style="width: 100%">
                  <thead>
                    <tr>
                      <th>Group</th>
                      <th>Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="resource in resourceSummary" :key="resource.id">
                      <td>{{ resource.plasticTypeGroup?.name }}</td>
                      <td>
                        {{
                          resource.plasticType?.name
                            ? resource.plasticType?.name
                            : "N/A"
                        }}
                      </td>
                      <td>
                        {{ resource.amount }}
                        {{
                          resource.amount > 1
                            ? resource.unit.plural
                            : resource.unit.singular
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-2" v-show="!resourceSummary.length && showSummary">No results found!</div>
            </div>
          </template>
        </filters>
      </template>

      <template #name="{ value }">
        <span style="white-space: nowrap">{{
          value?.pickupLocation?.name || "N/A"
        }}</span>
      </template>

      <template #createdBy.myAccountInfo.lastName="{ value }">
        <user :value="value.createdBy" />
      </template>

      <template #rain="{ value }">
        <Weather
          v-if="value?.pickupLocation?.weather"
          :value="value?.pickupLocation?.weather"
        />
      </template>

      <template #plasticTypeGroup="{ value }">
        <span style="white-space: nowrap">{{
          value.plasticTypeGroupName
        }}</span>
      </template>

      <template #plasticType="{ value }">
        <span style="white-space: nowrap">{{ value.plasticTypeName }}</span>
      </template>

      <template #unitValue="{ value }">
        <span style="white-space: nowrap">{{ value.plasticDesc }}</span>
      </template>

      <template #ageInDays="{ value }">
        <span :style="value.ageStyle">
          {{ value.ageFormatted }}
        </span>
      </template>

      <template #detail="{ value }">
        <div
          v-if="!value?.completedOn"
          class="has-background-light"
          style="padding-left: 10px; padding-top: 10px"
        >
          <a
            v-show="!closeOutPickupRequestDialogue"
            @click="() => (closeOutPickupRequestDialogue = true)"
            >Close Out</a
          >
          <div v-show="closeOutPickupRequestDialogue">
            <p style="font-weight: bold">
              This will mark the request as completed. Would you like to
              continue with the close out?
            </p>
            <button
              style="margin-right: 10px"
              @click="() => (closeOutPickupRequestDialogue = false)"
            >
              Back
            </button>
            <button @click="() => closeOutPickupRequest(value)">Confirm</button>
          </div>
        </div>

        <pickup-location-edit
          v-if="value.pickupLocation"
          :startingValue="value.pickupLocation"
        />

        <template v-if="inTpdMode">
          <div :style="{width: '100%', textAlign:'right', padding:'.5rem'}">
          <b-button @click="()=> hireTpd()" title="Hire Third Party Driver for This Request" size="is-small">
            Hire 3rd Party Driver
          </b-button>
        </div>
        </template>
      </template>
    </Table>

    <div style="display: none">
      <pickup-requests
        :pickupRequests="rows"
        @pickupRequestClicked="pickupRequestClicked"
      />

      <PickupLocation
        v-if="selectedPickupRequest && showPickupLocations"
        :simple="true"
        :pickupLocation="selectedPickupRequest.pickupLocation"
      />

      <filter-bounds v-if="filters.bbox" :bboxPath="filters.bbox" />
    </div>
  </div>
</template>

<script>
import Table from "@/components/common/Table";
import Weather from "@/components/common/Weather";
import Filters from "@/components/common/Filters.vue";
import User from "@/components/common/User.vue";
import InclusiveFilter from "@/components/common/InclusiveFilter.vue";

import Pp4MapObject from "@/components/maps/Pp4MapObject";
import PickupLocations from "@/components/maps/PickupLocations";
import PickupLocation from "@/components/maps/PickupLocation";
import FilterBounds from "@/components/maps/FilterBounds.vue";
import PickupRequests from "@/components/maps/PickupRequests.vue";

import PickupLocationEdit from "@/views/RouteWizard/components/PickupLocationEdit";
import BboxFilter from "@/views/RouteWizard/components/BboxFilter.vue";
import * as FormatUtil from "@/services/FormatUtil";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  extends: Pp4MapObject,
  render() {
    return null;
  },
  props: [],
  inject: ["sidebarStatus"],
  components: {
    User,
    Table,
    Filters,
    InclusiveFilter,
    BboxFilter,
    PickupLocationEdit,
    PickupLocations,
    PickupLocation,
    PickupRequests,
    FilterBounds,
    Weather,
  },
  data: function () {
    return {
      closeOutPickupRequestDialogue: false,
      selectedRowRowPointer: null,
      selectedPickupRequest: null,
      columns: [
        { field: "name", label: "Name", visible: true, sortable: true },
        {
          field: "createdBy.myAccountInfo.lastName",
          label: "Requestor",
          visible: true,
        },
        {
          field: "plasticTypeGroupName",
          label: "Group",
          visible: true,
          sortable: true,
          centered: true,
        },
        {
          field: "plasticTypeName",
          label: "Type",
          visible: true,
          sortable: true,
          centered: true,
        },
        {
          field: "unitValue",
          label: "Amount",
          visible: true,
          sortable: true,
          centered: true,
        },
        {
          field: "rain",
          label: "Rain",
          visible: true,
          sortable: true,
          centered: true,
        },
        {
          field: "ageInDays",
          label: "Age",
          visible: true,
          centered: true,
          sortable: true,
        },
      ],
      plasticTypeMulti: false,
      expandPlasticTypeMulti: true,
      showSummary: false,
    };
  },
  computed: {
    resourceSummary: (vm) => {
      const ret = vm.$store.state.paginatedPickupRequests.resourceSummary || [];
      return ret.map((o) => ({ ...o, id: uuidv4() }));
    },
    showPickupRequests: (vm) => vm.$store.state.layers.pickupRequests,
    showPickupLocations: (vm) => vm.$store.state.layers.pickupLocations,
    mapActivityActive: (vm) => vm.$store.state.mapActivityActive,
    states: (vm) => vm.$store.state.states,
    rows: (vm) => {
      return vm.rowPagination.data.map((ppl) => {
        const ret = { ...ppl };

        ret.name = ret.pickupLocation?.name || "Undefined";
        ret.rain = vm.getTotalRain(ret?.pickupLocation?.weather);
        ret.plasticTypeGroupName = ret?.plasticTypeGroup?.name || "N/A";
        ret.plasticTypeName = ret?.plasticType?.name || "N/A";
        ret.plasticDesc = ret.unitValue + " " + (ret?.unit?.plural || "");

        const createdOn = new Date(ret.createdOn);
        ret.ageFormatted = FormatUtil.formatAge(createdOn);

        ret.ageInDays = Math.round(
          (new Date().getTime() - createdOn.getTime()) / 1000 / 60 / 60 / 24
        );

        ret.bradColor = "red";
        if (ret.ageInDays < 7) {
          ret.bradColor = "green";
        } else if (ret.ageInDays <= 14) {
          ret.bradColor = "orange";
        }

        ret.ageStyle = `color: ${ret.bradColor}; white-space: nowrap;`;

        return ret;
      });
    },
    pickupLocations: (vm) => vm.rows.map((r) => r.pickupLocation),
    rowPagination: (vm) => vm.$store.state.paginatedPickupRequests,
    filters: (vm) => {
      const ret = vm.$store.state.paginatedPickupRequests.filters
      return ret
    },
    plasticTypeGroups: (vm) => vm.$store.state.plasticTypeGroups,
    plasticTypes: (vm) => {
      const ret = vm.$store.state.plasticTypes;

      const plasticTypeGroupId = vm.filters.plasticTypeGroupId;
      if (!Number.isFinite(plasticTypeGroupId)) {
        return ret;
      }

      return ret.filter((pt) => {
        return pt.plasticTypeGroupId === plasticTypeGroupId;
      });
    },
    inTpdMode: (vm) => vm.$store.state.inTpdMode,
    tpdSwrUrl: (vm) => vm.$store.state.tpdSwrUrl,
  },
  watch: {},
  mounted() {
    // console.log("updatePaginatedPickupRequests 0")
    // this.$store.dispatch("updatePaginatedPickupRequests");
  },
  created() {
    this.debounceRefresh = debounce(function () {
      this.$store.dispatch("updatePaginatedPickupRequests");
    }, 1000);
  },
  methods: {
    togglePlasticTypeMulti() {
      this.plasticTypeMulti = !this.plasticTypeMulti;
    },
    toggleExpandPlasticTypeMulti() {
      this.expandPlasticTypeMulti = !this.expandPlasticTypeMulti;
    },
    toggleShowSummary() {
      this.showSummary = !this.showSummary;
      console.log(this.showSummary);
    },
    closeOutPickupRequest(value) {
      this.$store.dispatch("closeOutPickupRequest", value);
      this.closeOutPickupRequestDialogue = false;
    },
    pickupLocationClicked(pl) {},
    // https://stackoverflow.com/questions/46925527/vue-js-bind-object-properties
    searchTextChanged(text) {
      this.updateFilter("searchText", text);
    },
    plasticTypeGroupIdChanged(e) {
      const newVal = e.target.value;
      const parsed = Number.parseInt(newVal);
      const val = Number.isFinite(parsed) ? parsed : null;
      this.updateFilter("plasticTypeGroupId", val);
    },
    plasticTypeIdChanged(e) {
      if (e.target.multiple) {
        const plasticTypeIds = [];

        // are you fucking serious?
        const options = e.target.options;
        for (let i = 0; i < options.length; i++) {
          const opt = options[i];

          if (opt.selected) {
            plasticTypeIds.push(Number.parseInt(opt.value));
          }
        }

        if (plasticTypeIds.length) {
          this.updateFilter("plasticTypeId", plasticTypeIds);
        } else {
          this.updateFilter("plasticTypeId", null);
        }
      } else {
        const newVal = e.target.value;
        const parsed = Number.parseInt(newVal);
        const val = Number.isFinite(parsed) ? parsed : null;
        this.updateFilter("plasticTypeId", val);
      }
    },
    async updateFilter(property, value) {
      const newFilters = { ...this.filters };
      newFilters[property] = value;
      await this.$store.dispatch("updatePaginatedPickupRequestsOptions", {
        filters: newFilters,
      });

      this.debounceRefresh();
    },
    refresh() {
      this.$store.dispatch("updatePaginatedPickupRequests");
    },
    async pageChange(newPage) {
      if(newPage === this.rowPagination.page) {
        return // dupe
      }
      
      await this.$store.dispatch("updatePaginatedPickupRequestsOptions", {
        page: newPage,
      });

      this.$store.dispatch("updatePaginatedPickupRequests");
    },
    rowOpenedHandler(id) {
      this.selectedRowRowPointer = id;

      const openedPickupRequest = this.rows.find((o) => o.rowPointer == id);

      if (openedPickupRequest) {
        this.selectedPickupRequest = openedPickupRequest;
        const pickupLocation = openedPickupRequest.pickupLocation;
        const geo = pickupLocation.geoJson;
        if (geo) {
          this.panToGeoJson(geo);
        }
      } else {
        this.selectedPickupRequest = null;
      }
    },
    getTotalRain(weather) {
      if (!weather) {
        return 0;
      }

      const rainProps = [
        "precip-0",
        "precip-1",
        "precip-2",
        "precip-3",
        "precip-4",
        "precip-5",
        "precip-6",
        "precip+1",
        "precip+2",
        "precip+3",
        "precip+4",
      ];

      return weather
        ? rainProps.reduce((accum, prop) => accum + weather[prop], 0)
        : 0;
    },
    pickupRequestClicked(e) {
      this.selectedRowRowPointer = e.rowPointer;
    },
    async hireTpd() {
      await this.$store.dispatch("postTpdPickupRequest", { pickupRequest: this.selectedPickupRequest, swrUrl:  this.tpdSwrUrl });
    }
  },
};
</script>

<style lang="css" scoped>
.align_flex{
  display: flex;
  align-items: center;
}
.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  max-width: 50vw;
  overflow: hidden;
}

.filters_columns {
  display: flex;
  flex-direction: column;
}
.filters_rows {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary_container {
  padding: 0.5em;
}

#pickup_location_plastic_type_group_id {
  align-self: flex-start;
  min-width: 8em;
}

#pickup_location_plastic_type_id {
  min-width: 8em;
}

#pickup_location_plastic_type_id[class="multi"] {
  height: 4em;
}

#pickup_location_plastic_type_id[class="expanded-multi"] {
  height: 8em;
}
</style>

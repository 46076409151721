<template>
   <div class="user-basics has-background-light">
      <b-loading :is-full-page="false" :active="busy"></b-loading>

      <div class="card">
         <div class="card-header ">
            <span style="display:flex; flex-direction: row; align-items: center; justify-content: flex-start;">
               <span :style="`padding: 0.25em`" class="has-text-weight-bold">
                  {{ displayedName }} / {{ value.userId }}
               </span>
            </span>
         </div>

         <div class="card-content" v-if="myAccount">
            <div class="mb-2">
               <span class="has-text-weight-bold">Address:</span><br />
               {{ myAccount.address1 }}<br />
               <template v-if="myAccount.address2?.length">
                  {{ myAccount.address2 }}<br />
               </template>
               {{ myAccount.city }} {{ myAccount.state }}, {{ myAccount.zip }}<br />
            </div>

            <div class="mb-2">
               <span class="has-text-weight-bold">Phone:</span> {{ myAccount.phone }}
            </div>

            <div class="mb-2">
               <span class="has-text-weight-bold">Org:</span> {{ myAccount.organizationName }}
            </div>

            <div v-if="myAccount.dealerId === '00000000-0000-0000-0000-000000000000'" class="has-text-weight-bold">
               No Dealer
            </div>
            <div v-else>
               <span class="has-text-weight-bold">Dealer: </span>
               {{ myAccount.dealerName }} (Cust # {{ myAccount.dealerSlCustNum }}, Zip: {{
         myAccount.dealerZip5 }})
            </div>
         </div>

         <!-- Max Rebate Amount-->
         <div class="card-content">
            <span class="has-text-weight-bold">Rebate Limit:</span>

            <individual-field v-slot="inputProps" @onSave="putUserMaxRebateAmount" @onReset="resetMaxRebateAmount" @onRemove="deleteMaxRebateAmount" :showRemove="true">
               <template v-if="inputProps.disabled">
                  <template v-if="maxRebateAmount?.maxAmount">
                     ${{ maxRebateAmount.maxAmount }}
                  </template>
                  <template v-else>
                     No Limit
                  </template>
               </template>

               <template v-else>
                  <input type="number" min="1000" step="1000" v-model="workingMaxRebateAmount" size="10"
                     placeholder="Limit">
               </template>
               <!-- <input v-if="inputProps.disabled" :disabled="inputProps.disabled" :value="computedLeadDriverName" />
               <div v-if="!inputProps.disabled">
                  <input list="drivers" name="driver" v-model="driver.following" required />
                  <datalist id="drivers">
                  <option disabled value="">Please select one</option>
                  <option v-for="(driver, index) in drivers" :key="index" :value="driver.id">
                     {{ getFullNameAny(driver.user) }}
                  </option>
                  </datalist>
               </div> -->
            </individual-field>
         </div>

         <!-- Current Balance Line Items -->
         <div class="card-content">
            <span class="has-text-weight-bold is-success">Balance: ${{ availableBalance }}</span><br />
               <div v-if="currentLineItems.length">
                  <div v-for="(item, index) in currentLineItems" :key="index" :title="item.notes"
                     style="flex-direction: row; display: flex; align-items: center;">
                     <span>${{ item.amount }} &#09{{ item.description }}&#09 from
                        <User :value="item.createdByUser" /> on {{ new Date(item.createdOn).toLocaleString() }}
                     </span>
               <icon-button v-if="item.creditId && (!item.rebateId)" icon="delete" class="ml-1"
                  title="Delete Unredeemd Credit" style="color: red;" @click="deleteCredit(item.creditId)" />
                  </div>
               </div>
         </div> 

         <!-- Redeemed Line Items -->
         <div class="card-content">
            <span class="has-text-weight-bold is-success">Redeemed: ${{ redeemedTotal }}</span><br />
           <div v-for="(item, index) in redeemedLineItems" :key="index" :title="item.notes"
               style="flex-direction: row; display: flex; align-items: center;">
               <span>${{ item.amount }} &#09{{ item.description }}&#09 from
                  <User :value="item.createdByUser" /> on {{ new Date(item.createdOn).toLocaleString() }}
               </span>
            </div>
         </div> 

         <!-- Expired Line Items -->
          <div class="card-content">
            <span class="has-text-weight-bold is-success">Expired: ${{ expiredTotal }}</span>

            <br />
         <div v-if="expiredLineItems.length"> 
           <div v-for="(item, index) in expiredLineItems" :key="index" :title="item.notes"
               style="flex-direction: row; display: flex; align-items: center;">
               <span>${{ item.amount }} &#09{{ item.description }}&#09 from
                  <User :value="item.createdByUser" /> on {{ new Date(item.createdOn).toLocaleString() }}
               </span>

               <icon-button v-if="item.creditId && (!item.rebateId)" icon="delete" class="ml-1"
                  title="Delete Unredeemd Credit" style="color: red;" @click="deleteCredit(item.creditId)" />
            </div>
         </div>
         </div> 

         <!-- Add Credit / Redeem Balance-->
         <div class="card-content">
            <div style="display: flex; justify-content: space-between;">
               <span>
                  <span class="has-text-weight-bold mr-1">Add Credit:</span>
                  <input type="number" min="0" step="1" v-model="creditAmount" id="credit-amount" size="8"
                     placeholder="Amount">
                  <input type="text" v-model="creditNotes" id="credit-notes" placeholder="Notes..." class="ml-2" />
                  <button class="ml-1" @click="addCredit" :disabled="addCreditDisabled">Add</button>
               </span>

               <span>
                  <button :disabled="redeemBalanceDisabled" @click="redeemBalance">Redeem Balance</button>
               </span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import IconButton from '@/components/common/IconButton.vue';
import User from '@/components/common/User.vue';
import IndividualField from "@/components/IndividualField.vue";
import { getFullNameAny } from '@/services/UserUtil';

export default {
   components: {
      IconButton, User, IndividualField
   },
   props: {
      value: {
         type: Object,
         required: true,
      },
   },
   inject: ["$api"],
   data: function () {
      return {
         creditAmount: null,
         creditNotes: '',
         balanceLineItems: [],
         maxRebateAmount: null,
         workingMaxRebateAmount: 0,
         busy: false
      }
   },
   computed: {
      displayedName: vm => getFullNameAny(vm.value),
      myAccount: vm => vm.value.myAccountInfo,
      addCreditDisabled: vm => {
         const parsed = Number.parseFloat(vm.creditAmount);
         return !(Number.isFinite(parsed) && (parsed > 0))
      },
      dealerValid: vm => {
         return vm.myAccount.dealerRebateEligible && (vm.myAccount.dealerId !== '00000000-0000-0000-0000-000000000000'
         && vm.myAccount.dealerId !== '11111111-1111-1111-1111-111111111111')
      },
      redeemBalanceDisabled: vm => {
         return (! vm.dealerValid)|| vm.availableBalance <= 0
      },
      availableLineItems: vm => vm.balanceLineItems.filter(i => !i.rebateId),
      currentLineItems: vm =>vm.balanceLineItems.filter(i=> !i.rebateId && i.expired !== 1),
      expiredLineItems: vm => vm.balanceLineItems.filter(i => !i.rebateId && i.expired === 1),
      redeemedLineItems: vm => {
         return vm.balanceLineItems.filter(i => i.rebateId)
      },
      availableBalance: vm => Math.round(vm.availableLineItems.reduce((accum, i) => {
         if (i.expired !== 1) {
            return accum + i.amount
         }
         return accum

      }, 0)),
      redeemedTotal: vm => Math.round(vm.redeemedLineItems.reduce((accum, i) => accum + i.amount, 0)),
      expiredTotal: vm => Math.round(vm.availableLineItems.reduce((accum, i) => {
         if (i.expired == 1) {
            return accum + i.amount
         }
         return accum
      }, 0)),
   },
   mounted() {
      this.fetchEverything()
   },
   methods: {
      async redeemBalance() {
         const confirmed = confirm("Redeem Balance on Behalf of User?")
         if(! confirmed) {
            return
         }

         await this.$api.postUserRebate(this.value.id)
         
         await this.fetchEverything()
      },
      async putUserMaxRebateAmount(e) {
         try {
            this.busy = true

            await this.$store.dispatch('putUserMaxRebateAmount', {
               userId: this.value.id,
               maxAmount: this.workingMaxRebateAmount
            })

            await this.fetchRebateMaxAmount()
         }
         finally {
            this.busy = false
         }
      },
      async deleteMaxRebateAmount() {
         console.log("!deleteMaxRebateAmount()")
         try {
            this.busy = true

            await this.$store.dispatch('deleteUserMaxRebateAmount', this.value.id)

            await this.fetchRebateMaxAmount()
         }
         finally {
            this.busy = false
         }
      },
      resetMaxRebateAmount() {
         this.workingMaxRebateAmount = this.maxRebateAmount?.maxAmount || 100000;
      },
      async fetchEverything() {
         try {
            this.busy = true

            await this.fetchUserBalanceLineItems();
            await this.fetchRebateMaxAmount()
         }
         finally {
            this.busy = false
         }
      },
      async fetchRebateMaxAmount() {
         this.maxRebateAmount = await this.$store.getters["userMaxRebateAmount"](this.value.id);
         this.workingMaxRebateAmount = this.maxRebateAmount?.maxAmount || 100000;
      },
      async fetchUserBalanceLineItems() {
         const newItems = await this.$store.getters["userBalanceLineItems"](this.value.id);

         newItems.sort((a, b) => {
            return new Date(b.createdOn) - new Date(a.createdOn);
         });

         this.balanceLineItems = newItems;
      },
      async addCredit() {
         await this.$store.dispatch("postCredit", {
            to: this.value.id,
            amount: Math.round(Number.parseFloat(this.creditAmount)),
            notes: this.creditNotes
         });

         this.$toast.success('Credit Added');

         this.creditAmount = null;
         this.creditNotes = '';

         await this.fetchUserBalanceLineItems();
      },
      async deleteCredit(id) {
         await this.$store.dispatch("deleteCredit", id)
         await this.fetchUserBalanceLineItems();
      }
   },
};
</script>

<style lang="css" scoped>
.user-basics {
   width: 100%;
   display: flex;
   flex-direction: column;
   background-color: red;
   padding: 0.5rem;
}

.card-header {
   padding: 0.25rem;
}
</style>